.product-page .products-list ul li a {

    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;

}

.product-page .products-list ul li a:hover  {
    text-decoration: none;
    color: #ffc107;
}

.product-page .products-list ul {
    margin-bottom: 2rem;
}

.product-page .products-list{
    list-style: none;
}



.product-page .products-list h5{
    color: #ffc107;
}