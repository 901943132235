.product-page .categories ul li {
    list-style: none;
    padding: 10px 0;
    display: flex;
    align-items: center;
}


.product-page .categories ul li i {
    color: #ffc107  ;
}

.product-page .categories ul li a{

    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;

}

.product-page .categories ul li a:hover, .product-page .categories ul li.active a  {
    text-decoration: none;
    color: #ffc107;
}