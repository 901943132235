.portfolio #portfolio-flters li a{
    /*cursor: pointer;*/
    /*display: inline-block;*/
    /*padding: 8px 18px 10px 18px;*/
    /*font-size: 14px;*/
    /*font-weight: 500;*/
    /*line-height: 1;*/
    color: #444444;
    /*margin: 0 3px 10px 3px;*/
    /*transition: all ease-in-out 0.3s;*/
    /*background: #fafafc;*/
    /*border-radius: 50px;*/
}

.portfolio #portfolio-flters li:hover a, .portfolio #portfolio-flters li.filter-active a{
    color: #414c64;
    /*background: #ffc107;*/
}